const ecoCodes = {
  A: [
    [ '00', 'Uncommon Opening' ],
    [ '01', 'Nimzovich-Larsen Attack' ],
    [ '02', "Bird's Opening" ],
    [ '03', "Bird's Opening" ],
    [ '04', 'Reti Opening' ],
    [ '05', 'Reti Opening' ],
    [ '06', 'Reti Opening' ],
    [ '07', "King's Indian Attack" ],
    [ '08', "King's Indian Attack" ],
    [ '09', 'Reti Opening' ],
    [ '10', 'English' ],
    [ '11', 'English, Caro-Kann Defensive System' ],
    [ '12', 'English with b3' ],
    [ '13', 'English' ],
    [ '14', 'English' ],
    [ '15', 'English' ],
    [ '16', 'English' ],
    [ '17', 'English' ],
    [ '18', 'English, Mikenas-Carls' ],
    [ '19', 'English, Mikenas-Carls, Sicilian Variation' ],
    [ '20', 'English' ],
    [ '21', 'English' ],
    [ '22', 'English' ],
    [ '23', 'English, Bremen System, Keres Variation' ],
    [ '24', 'English, Bremen System with ...g6' ],
    [ '25', 'English' ],
    [ '26', 'English' ],
    [ '27', 'English, Three Knights System' ],
    [ '28', 'English' ],
    [ '29', 'English, Four Knights, Kingside Fianchetto' ],
    [ '30', 'English, Symmetrical' ],
    [ '31', 'English, Symmetrical, Benoni Formation' ],
    [ '32', 'English, Symmetrical Variation' ],
    [ '33', 'English, Symmetrical' ],
    [ '34', 'English, Symmetrical' ],
    [ '35', 'English, Symmetrical' ],
    [ '36', 'English' ],
    [ '37', 'English, Symmetrical' ],
    [ '38', 'English, Symmetrical' ],
    [ '39', 'English, Symmetrical, Main line with d4' ],
    [ '40', "Queen's Pawn Game" ],
    [ '41', "Queen's Pawn Game (with ...d6)" ],
    [ '42', 'Modern Defense, Averbakh System' ],
    [ '43', 'Old Benoni' ],
    [ '44', 'Old Benoni Defense' ],
    [ '45', "Queen's Pawn Game" ],
    [ '46', "Queen's Pawn Game" ],
    [ '47', "Queen's Indian" ],
    [ '48', "King's Indian" ],
    [ '49', "King's Indian, Fianchetto without c4" ],
    [ '50', "Queen's Pawn Game" ],
    [ '51', 'Budapest Gambit' ],
    [ '52', 'Budapest Gambit' ],
    [ '53', 'Old Indian' ],
    [ '54', 'Old Indian, Ukrainian Variation, 4.Nf3' ],
    [ '55', 'Old Indian, Main line' ],
    [ '56', 'Benoni Defense' ],
    [ '57', 'Benko Gambit' ],
    [ '58', 'Benko Gambit' ],
    [ '59', 'Benko Gambit' ],
    [ '60', 'Benoni Defense' ],
    [ '61', 'Benoni' ],
    [ '62', 'Benoni, Fianchetto Variation' ],
    [ '63', 'Benoni, Fianchetto, 9...Nbd7' ],
    [ '64', 'Benoni, Fianchetto, 11...Re8' ],
    [ '65', 'Benoni, 6.e4' ],
    [ '66', 'Benoni' ],
    [ '67', 'Benoni, Taimanov Variation' ],
    [ '68', 'Benoni, Four Pawns Attack' ],
    [ '69', 'Benoni, Four Pawns Attack, Main line' ],
    [ '70', 'Benoni, Classical with 7.Nf3' ],
    [ '71', 'Benoni, Classical, 8.Bg5' ],
    [ '72', 'Benoni, Classical without 9.O-O' ],
    [ '73', 'Benoni, Classical, 9.O-O' ],
    [ '74', 'Benoni, Classical, 9...a6, 10.a4' ],
    [ '75', 'Benoni, Classical with ...a6 and 10...Bg4' ],
    [ '76', 'Benoni, Classical, 9...Re8' ],
    [ '77', 'Benoni, Classical, 9...Re8, 10.Nd2' ],
    [ '78', 'Benoni, Classical with ...Re8 and ...Na6' ],
    [ '79', 'Benoni, Classical, 11.f3' ],
    [ '80', 'Dutch' ],
    [ '81', 'Dutch' ],
    [ '82', 'Dutch, Staunton Gambit' ],
    [ '83', 'Dutch, Staunton Gambit' ],
    [ '84', 'Dutch' ],
    [ '85', 'Dutch, with c4 & Nc3' ],
    [ '86', 'Dutch' ],
    [ '87', 'Dutch, Leningrad, Main Variation' ],
    [ '88', 'Dutch, Leningrad, Main Variation with c6' ],
    [ '89', 'Dutch, Leningrad, Main Variation with Nc6' ],
    [ '90', 'Dutch' ],
    [ '91', 'Dutch Defense' ],
    [ '92', 'Dutch' ],
    [ '93', 'Dutch, Stonewall, Botvinnik Variation' ],
    [ '94', 'Dutch, Stonewall with Ba3' ],
    [ '95', 'Dutch, Stonewall' ],
    [ '96', 'Dutch, Classical Variation' ],
    [ '97', 'Dutch, Ilyin-Genevsky' ],
    [ '98', 'Dutch, Ilyin-Genevsky Variation with Qc2' ],
    [ '99', 'Dutch, Ilyin-Genevsky Variation with b3' ]
  ],
  B: [
    [ '00', "Uncommon King's Pawn Opening" ],
    [ '01', 'Scandinavian' ],
    [ '02', "Alekhine's Defense" ],
    [ '03', "Alekhine's Defense" ],
    [ '04', "Alekhine's Defense, Modern" ],
    [ '05', "Alekhine's Defense, Modern" ],
    [ '06', 'Robatsch' ],
    [ '07', 'Pirc' ],
    [ '08', 'Pirc, Classical' ],
    [ '09', 'Pirc, Austrian Attack' ],
    [ '10', 'Caro-Kann' ],
    [ '11', 'Caro-Kann, Two Knights, 3...Bg4' ],
    [ '12', 'Caro-Kann Defense' ],
    [ '13', 'Caro-Kann, Exchange' ],
    [ '14', 'Caro-Kann, Panov-Botvinnik Attack' ],
    [ '15', 'Caro-Kann' ],
    [ '16', 'Caro-Kann, Bronstein-Larsen Variation' ],
    [ '17', 'Caro-Kann, Steinitz Variation' ],
    [ '18', 'Caro-Kann, Classical' ],
    [ '19', 'Caro-Kann, Classical' ],
    [ '20', 'Sicilian' ],
    [ '21', 'Sicilian, 2.f4 and 2.d4' ],
    [ '22', 'Sicilian, Alapin' ],
    [ '23', 'Sicilian, Closed' ],
    [ '24', 'Sicilian, Closed' ],
    [ '25', 'Sicilian, Closed' ],
    [ '26', 'Sicilian, Closed, 6.Be3' ],
    [ '27', 'Sicilian' ],
    [ '28', "Sicilian, O'Kelly Variation" ],
    [ '29', 'Sicilian, Nimzovich-Rubinstein' ],
    [ '30', 'Sicilian' ],
    [ '31', 'Sicilian, Rossolimo Variation' ],
    [ '32', 'Sicilian' ],
    [ '33', 'Sicilian' ],
    [ '34', 'Sicilian, Accelerated Fianchetto' ],
    [
      '35',
      'Sicilian, Accelerated Fianchetto, Modern Variation with Bc4'
    ],
    [ '36', 'Sicilian, Accelerated Fianchetto' ],
    [ '37', 'Sicilian, Accelerated Fianchetto' ],
    [ '38', 'Sicilian, Accelerated Fianchetto, Maroczy Bind, 6.Be3' ],
    [ '39', 'Sicilian, Accelerated Fianchetto, Breyer Variation' ],
    [ '40', 'Sicilian' ],
    [ '41', 'Sicilian, Kan' ],
    [ '42', 'Sicilian, Kan' ],
    [ '43', 'Sicilian, Kan, 5.Nc3' ],
    [ '44', 'Sicilian' ],
    [ '45', 'Sicilian, Taimanov' ],
    [ '46', 'Sicilian, Taimanov Variation' ],
    [ '47', 'Sicilian, Taimanov (Bastrikov) Variation' ],
    [ '48', 'Sicilian, Taimanov Variation' ],
    [ '49', 'Sicilian, Taimanov Variation' ],
    [ '50', 'Sicilian' ],
    [ '51', 'Sicilian, Canal-Sokolsky (Rossolimo) Attack' ],
    [ '52', 'Sicilian, Canal-Sokolsky (Rossolimo) Attack' ],
    [ '53', 'Sicilian' ],
    [ '54', 'Sicilian' ],
    [ '55', 'Sicilian, Prins Variation, Venice Attack' ],
    [ '56', 'Sicilian' ],
    [ '57', 'Sicilian' ],
    [ '58', 'Sicilian' ],
    [ '59', 'Sicilian, Boleslavsky Variation, 7.Nb3' ],
    [ '60', 'Sicilian, Richter-Rauzer' ],
    [ '61', 'Sicilian, Richter-Rauzer, Larsen Variation, 7.Qd2' ],
    [ '62', 'Sicilian, Richter-Rauzer' ],
    [ '63', 'Sicilian, Richter-Rauzer Attack' ],
    [ '64', 'Sicilian, Richter-Rauzer Attack' ],
    [
      '65',
      'Sicilian, Richter-Rauzer Attack, 7...Be7 Defense, 9...Nxd4'
    ],
    [ '66', 'Sicilian, Richter-Rauzer Attack, 7...a6' ],
    [
      '67',
      'Sicilian, Richter-Rauzer Attack, 7...a6 Defense, 8...Bd7'
    ],
    [
      '68',
      'Sicilian, Richter-Rauzer Attack, 7...a6 Defense, 9...Be7'
    ],
    [
      '69',
      'Sicilian, Richter-Rauzer Attack, 7...a6 Defense, 11.Bxf6'
    ],
    [ '70', 'Sicilian, Dragon Variation' ],
    [ '71', 'Sicilian, Dragon, Levenfish Variation' ],
    [ '72', 'Sicilian, Dragon' ],
    [ '73', 'Sicilian, Dragon, Classical' ],
    [ '74', 'Sicilian, Dragon, Classical' ],
    [ '75', 'Sicilian, Dragon, Yugoslav Attack' ],
    [ '76', 'Sicilian, Dragon, Yugoslav Attack' ],
    [ '77', 'Sicilian, Dragon, Yugoslav Attack' ],
    [ '78', 'Sicilian, Dragon, Yugoslav Attack, 10.castle long' ],
    [ '79', 'Sicilian, Dragon, Yugoslav Attack, 12.h4' ],
    [ '80', 'Sicilian, Scheveningen' ],
    [ '81', 'Sicilian, Scheveningen, Keres Attack' ],
    [ '82', 'Sicilian, Scheveningen' ],
    [ '83', 'Sicilian' ],
    [ '84', 'Sicilian, Scheveningen' ],
    [ '85', 'Sicilian, Scheveningen, Classical' ],
    [ '86', 'Sicilian, Fischer-Sozin Attack' ],
    [ '87', 'Sicilian, Fischer-Sozin with ...a6 and ...b5' ],
    [ '88', 'Sicilian, Fischer-Sozin Attack' ],
    [ '89', 'Sicilian' ],
    [ '90', 'Sicilian, Najdorf' ],
    [ '91', 'Sicilian, Najdorf, Zagreb (Fianchetto) Variation' ],
    [ '92', 'Sicilian, Najdorf, Opocensky Variation' ],
    [ '93', 'Sicilian, Najdorf, 6.f4' ],
    [ '94', 'Sicilian, Najdorf' ],
    [ '95', 'Sicilian, Najdorf, 6...e6' ],
    [ '96', 'Sicilian, Najdorf' ],
    [ '97', 'Sicilian, Najdorf' ],
    [ '98', 'Sicilian, Najdorf' ],
    [ '99', 'Sicilian, Najdorf, 7...Be7 Main line' ]
  ],
  C: [
    [ '00', 'French Defense' ],
    [ '01', 'French, Exchange' ],
    [ '02', 'French, Advance' ],
    [ '03', 'French, Tarrasch' ],
    [ '04', 'French, Tarrasch, Guimard Main line' ],
    [ '05', 'French, Tarrasch' ],
    [ '06', 'French, Tarrasch' ],
    [ '07', 'French, Tarrasch' ],
    [ '08', 'French, Tarrasch, Open, 4.ed ed' ],
    [ '09', 'French, Tarrasch, Open Variation, Main line' ],
    [ '10', 'French' ],
    [ '11', 'French' ],
    [ '12', 'French, McCutcheon' ],
    [ '13', 'French' ],
    [ '14', 'French, Classical' ],
    [ '15', 'French, Winawer' ],
    [ '16', 'French, Winawer' ],
    [ '17', 'French, Winawer, Advance' ],
    [ '18', 'French, Winawer' ],
    [ '19', 'French, Winawer, Advance' ],
    [ '20', "King's Pawn Game" ],
    [ '21', 'Center Game' ],
    [ '22', 'Center Game' ],
    [ '23', "Bishop's Opening" ],
    [ '24', "Bishop's Opening" ],
    [ '25', 'Vienna' ],
    [ '26', 'Vienna' ],
    [ '27', 'Vienna Game' ],
    [ '28', 'Vienna Game' ],
    [ '29', 'Vienna Gambit' ],
    [ '30', "King's Gambit Declined" ],
    [ '31', "King's Gambit Declined, Falkbeer Counter Gambit" ],
    [ '32', "King's Gambit Declined, Falkbeer Counter Gambit" ],
    [ '33', "King's Gambit Accepted" ],
    [ '34', "King's Gambit Accepted" ],
    [ '35', "King's Gambit Accepted, Cunningham" ],
    [ '36', "King's Gambit Accepted, Abbazia Defense" ],
    [ '37', "King's Gambit Accepted" ],
    [ '38', "King's Gambit Accepted" ],
    [ '39', "King's Gambit Accepted" ],
    [ '40', "King's Knight Opening" ],
    [ '41', 'Philidor Defense' ],
    [ '42', 'Petrov Defense' ],
    [ '43', 'Petrov, Modern Attack' ],
    [ '44', "King's Pawn Game" ],
    [ '45', 'Scotch Game' ],
    [ '46', 'Three Knights' ],
    [ '47', 'Four Knights' ],
    [ '48', 'Four Knights' ],
    [ '49', 'Four Knights' ],
    [ '50', 'Giuoco Piano' ],
    [ '51', 'Evans Gambit' ],
    [ '52', 'Evans Gambit' ],
    [ '53', 'Giuoco Piano' ],
    [ '54', 'Giuoco Piano' ],
    [ '55', 'Two Knights Defense' ],
    [ '56', 'Two Knights' ],
    [ '57', 'Two Knights' ],
    [ '58', 'Two Knights' ],
    [ '59', 'Two Knights' ],
    [ '60', 'Ruy Lopez' ],
    [ '61', "Ruy Lopez, Bird's Defense" ],
    [ '62', 'Ruy Lopez, Old Steinitz Defense' ],
    [ '63', 'Ruy Lopez, Schliemann Defense' ],
    [ '64', 'Ruy Lopez, Classical' ],
    [ '65', 'Ruy Lopez, Berlin Defense' ],
    [ '66', 'Ruy Lopez' ],
    [ '67', 'Ruy Lopez' ],
    [ '68', 'Ruy Lopez, Exchange' ],
    [ '69', 'Ruy Lopez, Exchange, Gligoric Variation' ],
    [ '70', 'Ruy Lopez' ],
    [ '71', 'Ruy Lopez' ],
    [ '72', 'Ruy Lopez, Modern Steinitz Defense, 5.O-O' ],
    [ '73', 'Ruy Lopez, Modern Steinitz Defense' ],
    [ '74', 'Ruy Lopez, Modern Steinitz Defense' ],
    [ '75', 'Ruy Lopez, Modern Steinitz Defense' ],
    [
      '76',
      'Ruy Lopez, Modern Steinitz Defense, Fianchetto Variation'
    ],
    [ '77', 'Ruy Lopez' ],
    [ '78', 'Ruy Lopez' ],
    [ '79', 'Ruy Lopez, Steinitz Defense Deferred' ],
    [ '80', 'Ruy Lopez, Open' ],
    [ '81', 'Ruy Lopez, Open, Howell Attack' ],
    [ '82', 'Ruy Lopez, Open' ],
    [ '83', 'Ruy Lopez, Open' ],
    [ '84', 'Ruy Lopez, Closed' ],
    [ '85', 'Ruy Lopez, Exchange Variation Doubly Deferred (DERLD)' ],
    [ '86', 'Ruy Lopez, Worrall Attack' ],
    [ '87', 'Ruy Lopez' ],
    [ '88', 'Ruy Lopez' ],
    [ '89', 'Ruy Lopez, Marshall' ],
    [ '90', 'Ruy Lopez, Closed' ],
    [ '91', 'Ruy Lopez, Closed' ],
    [ '92', 'Ruy Lopez, Closed' ],
    [ '93', 'Ruy Lopez, Closed, Smyslov Defense' ],
    [ '94', 'Ruy Lopez, Closed, Breyer Defense' ],
    [ '95', 'Ruy Lopez, Closed, Breyer' ],
    [ '96', 'Ruy Lopez, Closed' ],
    [ '97', 'Ruy Lopez, Closed, Chigorin' ],
    [ '98', 'Ruy Lopez, Closed, Chigorin' ],
    [ '99', 'Ruy Lopez, Closed, Chigorin, 12...cd' ]
  ],
  D: [
    [ '00', "Queen's Pawn Game" ],
    [ '01', 'Richter-Veresov Attack' ],
    [ '02', "Queen's Pawn Game" ],
    [ '03', 'Torre Attack (Tartakower Variation)' ],
    [ '04', "Queen's Pawn Game" ],
    [ '05', "Queen's Pawn Game" ],
    [ '06', "Queen's Gambit Declined" ],
    [ '07', "Queen's Gambit Declined, Chigorin Defense" ],
    [ '08', "Queen's Gambit Declined, Albin Counter Gambit" ],
    [ '09', "Queen's Gambit Declined, Albin Counter Gambit, 5.g3" ],
    [ '10', "Queen's Gambit Declined Slav" ],
    [ '11', "Queen's Gambit Declined Slav" ],
    [ '12', "Queen's Gambit Declined Slav" ],
    [ '13', "Queen's Gambit Declined Slav, Exchange Variation" ],
    [ '14', "Queen's Gambit Declined Slav, Exchange Variation" ],
    [ '15', "Queen's Gambit Declined Slav" ],
    [ '16', "Queen's Gambit Declined Slav" ],
    [ '17', "Queen's Gambit Declined Slav" ],
    [ '18', "Queen's Gambit Declined Slav, Dutch" ],
    [ '19', "Queen's Gambit Declined Slav, Dutch" ],
    [ '20', "Queen's Gambit Accepted" ],
    [ '21', "Queen's Gambit Accepted" ],
    [ '22', "Queen's Gambit Accepted" ],
    [ '23', "Queen's Gambit Accepted" ],
    [ '24', "Queen's Gambit Accepted" ],
    [ '25', "Queen's Gambit Accepted" ],
    [ '26', "Queen's Gambit Accepted" ],
    [ '27', "Queen's Gambit Accepted, Classical" ],
    [ '28', "Queen's Gambit Accepted, Classical" ],
    [ '29', "Queen's Gambit Accepted, Classical" ],
    [ '30', "Queen's Gambit Declined" ],
    [ '31', "Queen's Gambit Declined" ],
    [ '32', "Queen's Gambit Declined, Tarrasch" ],
    [ '33', "Queen's Gambit Declined, Tarrasch" ],
    [ '34', "Queen's Gambit Declined, Tarrasch" ],
    [ '35', "Queen's Gambit Declined" ],
    [
      '36',
      "Queen's Gambit Declined, Exchange, Positional line, 6.Qc2"
    ],
    [ '37', "Queen's Gambit Declined" ],
    [ '38', "Queen's Gambit Declined, Ragozin Variation" ],
    [ '39', "Queen's Gambit Declined, Ragozin, Vienna Variation" ],
    [ '40', "Queen's Gambit Declined, Semi-Tarrasch" ],
    [ '41', "Queen's Gambit Declined, Semi-Tarrasch" ],
    [ '42', "Queen's Gambit Declined, Semi-Tarrasch, 7.Bd3" ],
    [ '43', "Queen's Gambit Declined Semi-Slav" ],
    [ '44', "Queen's Gambit Declined Semi-Slav" ],
    [ '45', "Queen's Gambit Declined Semi-Slav" ],
    [ '46', "Queen's Gambit Declined Semi-Slav" ],
    [ '47', "Queen's Gambit Declined Semi-Slav" ],
    [ '48', "Queen's Gambit Declined Semi-Slav, Meran" ],
    [ '49', "Queen's Gambit Declined Semi-Slav, Meran" ],
    [ '50', "Queen's Gambit Declined" ],
    [ '51', "Queen's Gambit Declined" ],
    [ '52', "Queen's Gambit Declined" ],
    [ '53', "Queen's Gambit Declined" ],
    [ '54', "Queen's Gambit Declined, Anti-Neo-Orthodox Variation" ],
    [ '55', "Queen's Gambit Declined" ],
    [ '56', "Queen's Gambit Declined" ],
    [ '57', "Queen's Gambit Declined, Lasker Defense" ],
    [
      '58',
      "Queen's Gambit Declined, Tartakower (Makagonov-Bondarevsky) System"
    ],
    [ '59', "Queen's Gambit Declined, Tartakower" ],
    [ '60', "Queen's Gambit Declined, Orthodox Defense" ],
    [ '61', "Queen's Gambit Declined, Orthodox, Rubinstein Attack" ],
    [ '62', "Queen's Gambit Declined, Orthodox, Rubinstein Attack" ],
    [ '63', "Queen's Gambit Declined, Orthodox Defense" ],
    [ '64', "Queen's Gambit Declined, Orthodox, Rubinstein Attack" ],
    [
      '65',
      "Queen's Gambit Declined, Orthodox, Rubinstein Attack, Main line"
    ],
    [ '66', "Queen's Gambit Declined, Orthodox Defense, Bd3 line" ],
    [ '67', "Queen's Gambit Declined, Orthodox Defense, Bd3 line" ],
    [ '68', "Queen's Gambit Declined, Orthodox Defense, Classical" ],
    [
      '69',
      "Queen's Gambit Declined, Orthodox Defense, Classical, 13.de"
    ],
    [ '70', 'Neo-Grunfeld Defense' ],
    [ '71', 'Neo-Grunfeld' ],
    [ '72', 'Neo-Grunfeld, 5.cd, Main line' ],
    [ '73', 'Neo-Grunfeld, 5.Nf3' ],
    [ '74', 'Neo-Grunfeld, 6.cd Nxd5, 7.O-O' ],
    [ '75', 'Neo-Grunfeld, 6.cd Nxd5, 7.O-O c5, 8.dxc5' ],
    [ '76', 'Neo-Grunfeld, 6.cd Nxd5, 7.O-O Nb6' ],
    [ '77', 'Neo-Grunfeld, 6.O-O' ],
    [ '78', 'Neo-Grunfeld, 6.O-O c6' ],
    [ '79', 'Neo-Grunfeld, 6.O-O, Main line' ],
    [ '80', 'Grunfeld' ],
    [ '81', 'Grunfeld, Russian Variation' ],
    [ '82', 'Grunfeld, 4.Bf4' ],
    [ '83', 'Grunfeld, Grunfeld Gambit' ],
    [ '84', 'Grunfeld, Grunfeld Gambit Accepted' ],
    [ '85', 'Grunfeld' ],
    [ '86', 'Grunfeld, Exchange' ],
    [ '87', 'Grunfeld, Exchange' ],
    [ '88', 'Grunfeld, Spassky Variation, Main line, 10...cd, 11.cd' ],
    [ '89', 'Grunfeld' ],
    [ '90', 'Grunfeld' ],
    [ '91', 'Grunfeld, 5.Bg5' ],
    [ '92', 'Grunfeld, 5.Bf4' ],
    [ '93', 'Grunfeld, with Bf4 & e3' ],
    [ '94', 'Grunfeld' ],
    [ '95', 'Grunfeld' ],
    [ '96', 'Grunfeld, Russian Variation' ],
    [ '97', 'Grunfeld, Russian' ],
    [ '98', 'Grunfeld, Russian' ],
    [ '99', 'Grunfeld Defense, Smyslov' ]
  ],
  E: [
    [ '00', "Queen's Pawn Game" ],
    [ '01', 'Catalan, Closed' ],
    [ '02', 'Catalan, Open, 5.Qa4' ],
    [ '03', 'Catalan, Open' ],
    [ '04', 'Catalan, Open, 5.Nf3' ],
    [ '05', 'Catalan, Open, Classical line' ],
    [ '06', 'Catalan, Closed, 5.Nf3' ],
    [ '07', 'Catalan, Closed' ],
    [ '08', 'Catalan, Closed' ],
    [ '09', 'Catalan, Closed' ],
    [ '10', "Queen's Pawn Game" ],
    [ '11', 'Bogo-Indian Defense' ],
    [ '12', "Queen's Indian" ],
    [ '13', "Queen's Indian, 4.Nc3, Main line" ],
    [ '14', "Queen's Indian" ],
    [ '15', "Queen's Indian" ],
    [ '16', "Queen's Indian" ],
    [ '17', "Queen's Indian" ],
    [ '18', "Queen's Indian, Old Main line, 7.Nc3" ],
    [ '19', "Queen's Indian, Old Main line, 9.Qxc3" ],
    [ '20', 'Nimzo-Indian' ],
    [ '21', 'Nimzo-Indian, Three Knights' ],
    [ '22', 'Nimzo-Indian, Spielmann Variation' ],
    [ '23', 'Nimzo-Indian, Spielmann' ],
    [ '24', 'Nimzo-Indian, Samisch' ],
    [ '25', 'Nimzo-Indian, Samisch' ],
    [ '26', 'Nimzo-Indian, Samisch' ],
    [ '27', 'Nimzo-Indian, Samisch Variation' ],
    [ '28', 'Nimzo-Indian, Samisch Variation' ],
    [ '29', 'Nimzo-Indian, Samisch' ],
    [ '30', 'Nimzo-Indian, Leningrad' ],
    [ '31', 'Nimzo-Indian, Leningrad, Main line' ],
    [ '32', 'Nimzo-Indian, Classical' ],
    [ '33', 'Nimzo-Indian, Classical' ],
    [ '34', 'Nimzo-Indian, Classical, Noa Variation' ],
    [ '35', 'Nimzo-Indian, Classical, Noa Variation, 5.cd ed' ],
    [ '36', 'Nimzo-Indian, Classical' ],
    [ '37', 'Nimzo-Indian, Classical' ],
    [ '38', 'Nimzo-Indian, Classical, 4...c5' ],
    [ '39', 'Nimzo-Indian, Classical, Pirc Variation' ],
    [ '40', 'Nimzo-Indian, 4.e3' ],
    [ '41', 'Nimzo-Indian' ],
    [ '42', 'Nimzo-Indian, 4.e3 c5, 5.Ne2 (Rubinstein)' ],
    [ '43', 'Nimzo-Indian, Fischer Variation' ],
    [ '44', 'Nimzo-Indian, Fischer Variation, 5.Ne2' ],
    [ '45', 'Nimzo-Indian, 4.e3, Bronstein (Byrne) Variation' ],
    [ '46', 'Nimzo-Indian' ],
    [ '47', 'Nimzo-Indian, 4.e3 O-O 5.Bd3' ],
    [ '48', 'Nimzo-Indian, 4.e3 O-O 5.Bd3 d5' ],
    [ '49', 'Nimzo-Indian, 4.e3, Botvinnik System' ],
    [ '50', 'Nimzo-Indian, 4.e3 O-O 5.Nf3, without ...d5' ],
    [ '51', 'Nimzo-Indian, 4.e3' ],
    [ '52', 'Nimzo-Indian, 4.e3, Main line with ...b6' ],
    [ '53', 'Nimzo-Indian, 4.e3' ],
    [ '54', 'Nimzo-Indian, 4.e3, Gligoric System' ],
    [
      '55',
      'Nimzo-Indian, 4.e3, Gligoric System, Bronstein Variation'
    ],
    [ '56', 'Nimzo-Indian, 4.e3, Main line with 7...Nc6' ],
    [ '57', 'Nimzo-Indian, 4.e3, Main line with 8...dc and 9...cd' ],
    [ '58', 'Nimzo-Indian, 4.e3, Main line with 8...Bxc3' ],
    [ '59', 'Nimzo-Indian, 4.e3, Main line' ],
    [ '60', "King's Indian Defense" ],
    [ '61', "King's Indian" ],
    [ '62', "King's Indian, Fianchetto" ],
    [ '63', "King's Indian, Fianchetto, Panno Variation" ],
    [ '64', "King's Indian, Fianchetto, Yugoslav System" ],
    [ '65', "King's Indian, Fianchetto, Yugoslav, 7.O-O" ],
    [ '66', "King's Indian, Fianchetto, Yugoslav Panno" ],
    [ '67', "King's Indian, Fianchetto" ],
    [ '68', "King's Indian, Fianchetto, Classical Variation, 8.e4" ],
    [ '69', "King's Indian, Fianchetto, Classical Main line" ],
    [ '70', "King's Indian" ],
    [ '71', "King's Indian, Makagonov System (5.h3)" ],
    [ '72', "King's Indian" ],
    [ '73', "King's Indian" ],
    [ '74', "King's Indian, Averbakh, 6...c5" ],
    [ '75', "King's Indian, Averbakh, Main line" ],
    [ '76', "King's Indian, Four Pawns Attack" ],
    [ '77', "King's Indian" ],
    [ '78', "King's Indian, Four Pawns Attack, with Be2 and Nf3" ],
    [ '79', "King's Indian, Four Pawns Attack, Main line" ],
    [ '80', "King's Indian, Samisch Variation" ],
    [ '81', "King's Indian, Samisch" ],
    [ '82', "King's Indian, Samisch, double Fianchetto Variation" ],
    [ '83', "King's Indian, Samisch" ],
    [ '84', "King's Indian, Samisch, Panno Main line" ],
    [ '85', "King's Indian, Samisch, Orthodox Variation" ],
    [ '86', "King's Indian, Samisch, Orthodox, 7.Nge2 c6" ],
    [ '87', "King's Indian, Samisch, Orthodox" ],
    [ '88', "King's Indian, Samisch, Orthodox, 7.d5 c6" ],
    [ '89', "King's Indian, Samisch, Orthodox Main line" ],
    [ '90', "King's Indian" ],
    [ '91', "King's Indian" ],
    [ '92', "King's Indian" ],
    [ '93', "King's Indian, Petrosian System" ],
    [ '94', "King's Indian, Orthodox" ],
    [ '95', "King's Indian, Orthodox, 7...Nbd7, 8.Re1" ],
    [ '96', "King's Indian, Orthodox, 7...Nbd7, Main line" ],
    [ '97', "King's Indian" ],
    [ '98', "King's Indian, Orthodox, Taimanov, 9.Ne1" ],
    [ '99', "King's Indian, Orthodox, Taimanov" ]
  ]
}


export default ecoCodes;